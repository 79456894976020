// for search
import React from 'react'
import styled, { withTheme } from 'styled-components'
import ItemArticle from '../../../Article/components/ItemArticle/index'
import ContainerFull from '../../../../components/ContainerFull/index'

const Wrapper = styled(ContainerFull)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const StyledItemArticle = styled(ItemArticle)`
  max-width: ${(props) => props.theme.sizes.col6};
  min-width: ${(props) => props.theme.sizes.col6};
  margin-top: ${(props) => props.theme.sizes.gapVerticalHalf};
  :nth-child(odd) {
    padding-right: ${(props) => props.theme.sizes.gapColHalf};
  }
  :nth-child(1) {
    margin-top: 0;
  }
  :nth-child(2) {
    margin-top: 0;
  }
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    :nth-child(odd) {
      padding-right: ${(props) => props.theme.tablet.sizes.gapColHalf};
    }
    :nth-child(2) {
      margin-top: ${(props) => props.theme.tablet.sizes.gapVerticalHalf};
    }
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    padding: 0;
    border-right: 0px;
    :nth-child(odd) {
      padding-right: 0;
    }
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    :nth-child(odd) {
      padding-right: ${(props) => props.theme.mobile.sizes.gapColHalf};
    }
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    padding: 0;
    :nth-child(odd) {
      padding-right: 0;
    }
  }
`

const WidgetVideos = ({ articles, ...props }) => (
  <Wrapper cinemaMode="multimedia" noGapEdge={props.theme.mode !== 'desktop' ? true : false} {...props}>
    {articles.map((article, index) => {
      if (index % 2) {
        return (
          <StyledItemArticle
            view="small_portrait"
            borderBottom="none"
            article={article}
            inverted={1}
            inverted_background_color="backgroundSecondary"
            nextToAnother={true}
          />
        )
      } else {
        return (
          <StyledItemArticle
            view="small_portrait"
            borderBottom="none"
            borderRight={props.theme.mode === 'desktop' ? '1px solid #fff' : 'none'}
            article={article}
            inverted={1}
            inverted_background_color="backgroundSecondary"
            nextToAnother={true}
          />
        )
      }
    })}
  </Wrapper>
)

export default withTheme(WidgetVideos)
