import React, { Fragment, useState } from 'react'
import styled, { withTheme } from 'styled-components'
import TitleTopic from '../../../../../../../../components/TitleTopic'
import CategoryArticlesPanel from '../../../../../../../../components/CategoryArticlesPanel'
import Block from '../../../../../../../../layouts/Blocks'
import ItemArticle from '../../../../../../../Article/components/ItemArticle'
import PropTypes from 'prop-types'
import hasItems from '../../../../../../../../modules/has-items'
import LoadMore from '../../../../../../../../components/LoadMore'
import HeaderVideo from '../../../../../../components/HeaderVideo'
import ContainerFull from '../../../../../../../../components/ContainerFull'
import WidgetVideos from '../../../../../../widgets/WidgetVideos'

const Wrapper = styled(ContainerFull)``

const StyledHeaderVideo = styled(HeaderVideo)`
  padding-left: ${(props) => props.theme.sizes.gapEdge};
  padding-right: ${(props) => props.theme.sizes.gapEdge};
  padding-bottom: 20px;
  background-color: ${(props) => props.theme.color.multimedia.backgroundTertiary};
  margin-bottom: ${(props) => props.theme.sizes.gapVertical};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding-left: ${(props) => props.theme.tablet.sizes.gapEdge};
    padding-right: ${(props) => props.theme.tablet.sizes.gapEdge};
    margin-bottom: ${(props) => props.theme.tablet.sizes.gapVertical};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-left: ${(props) => props.theme.mobile.sizes.gapEdge};
    padding-right: ${(props) => props.theme.mobile.sizes.gapEdge};
    margin-bottom: ${(props) => props.theme.mobile.sizes.gapVertical};
  }
`

const StyledWidgetVideos = styled(WidgetVideos)`
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding-left: ${(props) => props.theme.tablet.sizes.gapEdge};
    padding-right: ${(props) => props.theme.tablet.sizes.gapEdge};
  }

  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-left: 0;
    padding-right: 0;
  }
`

const ListVideos = ({ data: { articles }, ...props }) => {
  const [helperArticles, setArticles] = useState(articles)
  return (
    <Wrapper noGapEdge cinemaMode="multimedia" {...props}>
      <StyledHeaderVideo />
      {hasItems(articles) && (
        <CategoryArticlesPanel indicatorColor="#fff" color="#fff" articles={helperArticles}>
          {(categoryArticles) => (
            <Fragment>
              <Block
                data={{ articles: [categoryArticles[0]] }}
                inverted={1}
                inverted_background_color="backgroundSecondary"
                borderBottom="none"
                view="big_one_article"
                adIds={['S3', 'S4']}
              />
              <StyledWidgetVideos articles={categoryArticles.slice(1)} />
              <LoadMore
                color="#fff"
                articles={categoryArticles}
                afterLoadMore={(newArticles) => {
                  setArticles(newArticles)
                }}
              />
            </Fragment>
          )}
        </CategoryArticlesPanel>
      )}
    </Wrapper>
  )
}

ListVideos.propTypes = {
  data: PropTypes.shape({
    ...TitleTopic.propTypes,
    articles: PropTypes.arrayOf(ItemArticle.propTypes),
  }),
}

ListVideos.defaultProps = {
  data: {
    articles: {},
  },
}

export default withTheme(ListVideos)
